
<script lang="ts">
    export interface IProps {
        type: "button" | "checkbox";
        text?: string;
        title?: string;
        href?: string;
        // onClick?: string;
    };

    export interface IEmits {
        // (e: "click"): void;
        (e: "change", val: any): void;
    };
</script>

<script setup lang="ts">
    import { watch } from "vue";
    import { isMobile } from "o365.GlobalState.ts";

    // TODO: move to core components
    import { OSwitch } from "o365-ui-components";

    const props = defineProps<IProps>();
    const emit = defineEmits<IEmits>();
    const model = defineModel<any>();

    watch(model, (val) => emit("change", val));
</script>

<template>
    <template v-if="isMobile">
        <template v-if="type === 'button'">
            <div class="d-flex">
                <OButton grow :text="text" :href="href" />
            </div>
        </template>
        <template v-else-if="type === 'checkbox'">
            <div class="d-flex justify-content-between text-muted">
                <div>{{ text }}</div>
                <OSwitch v-model="model" />
            </div>
        </template>
    </template>
    <template v-else>
        <div class="flex-shrink-0">
            <template v-if="type === 'button'">
                <OButton size="lg" variant="link" :text="text" :href="href" newTab />
            </template>
            <template v-else-if="type === 'checkbox'">
                <OButton size="lg" variant="link" :text="text" :title="title" :icon="model ? 'bi-check-square' : 'bi-square'" @click="model = !model" />
            </template>
        </div>
    </template>
</template>
